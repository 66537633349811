import * as React from "react"

import { Link } from "gatsby"
import SEO from "../components/seo"
import Section from "../components/section"

const NotFoundPage = () => (
  <Section class="font-sans">
    <SEO title="404 : page non trouvée" />
    <h1 className="font-serif text-3xl my-3 text-secondary-700 text-left">
      Page non trouvée
    </h1>
    <Link to="/">Revenir à l'accueil</Link>
  </Section>
)

export default NotFoundPage
